import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommanService } from 'src/app/comman.service';
import { VerifiedUserService } from './verified-user.service';
import jwt_decode from 'jwt-decode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/services/notification.service';
import { BankDetailsService } from './bank-details/bank-details.service';
export const Breakpoints = {
  Handset: '(max-width: 991px)',
};

@Component({
  selector: 'app-verified-user',
  templateUrl: './verified-user.component.html',
})
export class VerifiedUserComponent implements OnInit {
  profileDetail: any[] = [];
  profileFirstName: any;
  profileLastName: any;
  profileImage: any;
  StripeBankId: any;
  _id: any;
  token: any;
  decode: any;

  panelOpenState = false;
  title!: string;
  userName = '';

  @ViewChild('drawer') drawer: any;
  dataSource: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private commanService: CommanService,
    private verifiedUserService: VerifiedUserService,
    private modalService: NgbModal,
    private mainNotify: NotificationService,
    private BankDetails: BankDetailsService,
  ) {
    this.token = localStorage.getItem('auth_token');
    if (this.token && this.token != null) {
      this.decode = jwt_decode(this.token);
      this._id = this.decode._id;
    }
  }

  ngOnInit() {
    this.getProfileName();
    this.commanService.title.subscribe(title => {
      this.title = title;
    });

    this.BankDetails.bankCount$.subscribe((data: any) => {
      this.getProfileName();
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay(),
  );
  LogoutBody: any;

  closeSideNav() {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }
  }

  viewSherpaProfile() {
    this.verifiedUserService.getSherpaProfileView(this._id).subscribe((data: any) => {
      this.profileDetail = data['data'];
      this.router.navigate(['/verified-user/sherpa-profile']);
    });
  }
  dataLength: any;


  getProfileName() {
    this.verifiedUserService.getSherpaProfileView(this._id).subscribe((data: any) => {
      this.profileFirstName = data['data'].FirstName;
      this.profileLastName = data['data'].LastName;
      this.profileImage = data['data'].Image;
      this.StripeBankId = data['data'].StripeBankId;
    });
  }

  // Check if banklist is empty or 0
  isBankListEmpty() {
    return !this.StripeBankId;
  }

  ChangePassword() {
    this.router.navigate(['/change-password']);
  }

  openVerticallyCentered(delete_form: any) {
    this.modalService.open(delete_form, { centered: true, windowClass: 'delete-form' });
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('role');
    this.router.navigate(['login']);
    this.mainNotify.showSuccess('You have successfully logged out.', '');
  }
}
